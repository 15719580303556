<template>
    <v-container>
        <PageTitle>
            Inventory Form
        </PageTitle>

        <FormSubmittedMessage
            v-if="!showForm"
            :button-text="message.buttonText"
            :onClick="resetForm"
            :type="message.type"
        >
            {{ message.text }}
        </FormSubmittedMessage>

        <v-form v-if="showForm" ref="form" v-model="formValid">
            <!-- Search Options -->
            <v-card class="my-6">
                <v-tabs
                    v-model="tabs"
                    background-color="tabHeaderBackground"
                    centered
                    class="my-4"
                    fixed-tabs
                    float
                    grows
                    slider-size="4"
                >
                    <v-tab>Search by Location</v-tab>
                    <v-tab>Search by Part Number + Description</v-tab>
                    <v-tab>Search by Job Number</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tabs">
                    <!-- For Search by Location tab -->
                    <v-tab-item class="my-6 pa-2">
                        <v-row>
                            <v-col>
                                <WarehouseLocationsAutocomplete
                                    v-model="location"
                                    :loading-data="loadingImport"
                                    @selected="locationSelected"
                                >

                                </WarehouseLocationsAutocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>
                        <v-row v-if="selectedLocation">
                            <v-col class="px-4 py-2">
                                <VCard class="grey darken-3">
                                    <VCardSubtitle>
                                        Rack Photos
                                    </VCardSubtitle>
                                    <VCardText>
                                        <PhotoManager
                                            :photos="rackPhotos"
                                            :rack="selectedRack"
                                            :section="selectedSection"
                                            :subSection="selectedSubSection"
                                            category="rack-photos"
                                        ></PhotoManager>
                                    </VCardText>
                                </VCard>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <!-- For Search by Part number and Description tab -->
                    <v-tab-item class="my-8 mx-4 pa-2">
                        <v-row>
                            <v-col>
                                <v-autocomplete
                                    v-model="chosenItems2"
                                    :items="partNumbers"
                                    :loading="loadingJobAndParts || loadingImport"
                                    clearable
                                    deletable-chips
                                    label="Part Number & Description"
                                    multiple
                                    outlined
                                    placeholder="Start typing to Search"
                                    prepend-inner-icon="mdi-database-search"
                                    return-object
                                    small-chips
                                    @blur="onPartNumberChange"
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <!-- For Search by Job Number tab -->
                    <v-tab-item class="my-8 mx-4 pa-2">
                        <v-row>
                            <v-col>
                                <v-autocomplete
                                    v-model="selectedJob"
                                    :filter="filterJobs"
                                    :items="jobs"
                                    :loading="loadingJobAndParts"
                                    clearable
                                    item-text="job_number"
                                    item-value="id"
                                    label="Job Number"
                                    outlined
                                    placeholder="Start typing to Search"
                                    prepend-inner-icon="mdi-briefcase-search"
                                    return-object
                                >
                                    <template v-slot:selection="data">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ data.item.job_number }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle v-if="data.item.company">
                                                <i>{{ data.item.company.slug }}</i> -
                                                <b>{{ data.item.description }}</b>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                    <template v-slot:item="data">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ data.item.job_number }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                <span>
                                                    <i v-if="data.item.company">{{ data.item.company.slug }}</i> -
                                                    <b>{{ data.item.description }}</b>
                                                </span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>

            <!-- Display parts -->
            <div v-show="loadingImport" class="text-center pa-5">
                <v-progress-circular indeterminate size="50"></v-progress-circular>
            </div>

            <div v-show="!loadingImport">
                <h4>{{ availableParts.length }} items</h4>
                <v-divider class="my-2"/>

                <v-card
                    v-for="(part, p) in availableParts"
                    :key="part.warehouseInventoryItemId"
                    class="my-3"
                >
                    <v-card-text>
                        <v-row dense>
                            <VCol class="text-center" cols="12" lg="2" md="2" sm="2">
                                <VCard width="50">
                                    <VCardText class="pa-0">
                                        <div v-if="part.photos && part.photos.length > 0">
                                            <PhotoThumbnail :photo="part.photos[0]"></PhotoThumbnail>
                                        </div>
                                        <div v-else>
                                            <v-icon class="grey pa-4 mx-auto" large width="150">mdi-camera</v-icon>
                                        </div>
                                        <VBtn absolute class="mx-auto mt-3" color="primary" fab right top x-small
                                              @click="openPhotoDialog(part)">
                                            <VIcon x-small>mdi-pencil</VIcon>
                                        </VBtn>
                                    </VCardText>
                                </VCard>
                            </VCol>
                            <VCol cols="10">
                                <v-row>
                                    <v-col cols="12" sm="3">
                                        <div class="font-weight-bold">PO Number</div>
                                        {{ part.partPONumber }}
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <div class="font-weight-bold">Job</div>
                                        {{ part.jobNo }}
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <div class="font-weight-bold">Part Number</div>
                                        {{ part.partComboName }}
                                    </v-col>
                                    <v-col cols="12" sm="2">
                                        <v-text-field
                                            v-model="selectedParts[p].partQuantity"
                                            :disabled="
                    selectedParts[p].inventoryStatus.toLowerCase() !== 'confirm'
                  "
                                            :label="selectedParts[p].partQuantityLabel"
                                            :rules="partQuantityRule2(selectedParts[p], p)"
                                            dense
                                            hide-details="auto"
                                            outlined
                                            type="number"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="pt-0" cols="12" lg="4" sm="4">
                                        <div class="font-weight-bold">Current Location</div>
                                        {{ part.rack }} - {{ part.section }} - {{ part.subSection }}
                                    </v-col>
                                    <v-col class="pt-0" cols="12" lg="4" sm="4">
                                        <div class="font-weight-bold">Manager</div>
                                        {{ selectedParts[p].manager }}
                                    </v-col>
                                    <v-col class="pt-0" cols="12" lg="4" sm="4">
                                        <div class="font-weight-bold">Last Updated</div>
                                        {{ selectedParts[p].inventoryTimestamp }}
                                    </v-col>
                                    <v-col v-show="selectedParts[p].kitName" class="pt-0" cols="12">
                                        <v-text-field
                                            :items="existingKitNames"
                                            :value="selectedParts[p].kitName"
                                            dense
                                            disabled
                                            hide-details="auto"
                                            label="Kit Name"
                                            outlined
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </VCol>
                        </v-row>
                    </v-card-text>
                    <v-card-text>
                        <v-card color="#2b2a2a"
                                elevation="2"
                                outlined
                        >
                            <v-card-text>
                                <v-row>
                                    <v-col cols="9">
                                        <v-radio-group
                                            v-model="selectedParts[p].inventoryStatus"
                                            :rules="[requiredField]"
                                            class="pt-0 mt-0"
                                            hide-details="auto"
                                            mandatory
                                            row
                                            @change="updateOnNewInventoryStatus(selectedParts[p], p)"
                                        >
                                            <v-radio
                                                v-for="n in ['Undetermined', 'Confirm', 'Not Found', 'Move']"
                                                :key="`inv-action-radio-${n}`"
                                                :label="`${n}`"
                                                :value="n"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <div v-if="selectedParts[p].newLocation">
                                <v-card-subtitle>New Location</v-card-subtitle>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" md="2" sm="3">
                                            <v-select
                                                v-model="selectedParts[p].newLocationDetails.packageType"
                                                :items="packageTypes"
                                                dense
                                                label="Package Type"
                                                outlined
                                                @change="
                  selectedParts[p].newLocationDetails.rack = null;
                  selectedParts[p].newLocationDetails.section = null;
                  selectedParts[p].newLocationDetails.subSection = null;
                "
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" md="2" sm="3">
                                            <v-select
                                                v-model="selectedParts[p].newLocationDetails.rack"
                                                :items="availableRacks(selectedParts[p].newLocationDetails)"
                                                :rules="selectedParts[p].newLocation ? [requiredField] : []"
                                                dense
                                                label="New rack:"
                                                outlined
                                                required
                                                @change="
                  selectedParts[p].newLocationDetails.section = null;
                  selectedParts[p].newLocationDetails.subSection = null;
                "
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" md="" sm="3">
                                            <v-select
                                                v-model="selectedParts[p].newLocationDetails.section"
                                                :items="availableSections(selectedParts[p].newLocationDetails)"
                                                :rules="selectedParts[p].newLocation ? [requiredField] : []"
                                                dense
                                                label="New section:"
                                                outlined
                                                required
                                                @change="selectedParts[p].newLocationDetails.subSection = null"
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" md="3" sm="4">
                                            <v-select
                                                v-model="selectedParts[p].newLocationDetails.subSection"
                                                :items="
                  availableSubSections(selectedParts[p].newLocationDetails)
                "
                                                :rules="selectedParts[p].newLocation ? [requiredField] : []"
                                                dense
                                                label="New sub section:"
                                                outlined
                                                required
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" md="3" sm="4">
                                            <v-text-field
                                                v-model="selectedParts[p].newLocationDetails.quantity"
                                                :rules="
                  selectedParts[p].newLocation
                    ? partQuantityRule2(selectedParts[p], p)
                    : []
                "
                                                dense
                                                label="Amount at new location"
                                                outlined
                                                type="number"
                                                @blur="updateAmountAtCurrentLocation(selectedParts[p], p)"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </div>
                        </v-card>
                    </v-card-text>
                    <v-card-text class="pt-0 mt-0">

                    </v-card-text>
                </v-card>

                <v-card
                    v-show="availableParts.length > 0"
                >
                    <v-card-text class="pb-4">
                        <NotesTextField
                            v-model="inventoryNotes"
                            label="Inventory Notes"
                            rows="1"
                        />
                    </v-card-text>
                </v-card>

                <!-- Submit button -->
                <v-row class="mt-4">
                    <v-col>
                        <FormSubmitButton
                            :submitting="formSubmitting"
                            :valid="formValid && availableParts.length > 0"
                            @click="submitForm"
                        />
                    </v-col>
                </v-row>
            </div>
        </v-form>

        <v-dialog v-model="rackPhotoDialog" fullscreen>

            <v-card class="grey darken-3">

                <v-card-title>Rack Photos</v-card-title>

                <v-card-text>
                    <v-alert type="info">Rack Photos are required for the following racks</v-alert>

                    <v-card
                        v-for="item in rackPhotoData"
                        :key="item.key"
                        class="my-2"
                        elevation="5"
                    >
                        <v-card-title>{{ item.rack }} - {{ item.section }} - {{ item.subSection }}</v-card-title>
                        <v-card-text>
                            <PhotoManager
                                :photos.sync="item.photos"
                                :rack="item.rack"
                                :section="item.section"
                                :subSection="item.subSection"
                                :warehouse-inventory-item-id="item.warehouseInventoryItemId"
                                category="rack-photos"
                                @failed="rackPhotoUploadFailed"
                                @uploaded="rackPhotoUploaded"
                            ></PhotoManager>
                        </v-card-text>
                    </v-card>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" @click="completeRackPhotoDialog">Complete</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" @click="rackPhotoDialog = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="photoDialog" scrollable>
            <v-card>
                <v-card-title>Photo Manager</v-card-title>
                <v-card-subtitle>{{ photoDialogData.subTitle }}</v-card-subtitle>
                <v-card-text>
                    <PhotoManager
                        :photos="photoDialogData.photos"
                        :warehouse-inventory-item-id="photoDialogData.warehouseInventoryItemId"
                    ></PhotoManager>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="photoDialog = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import {get} from "@/client/utilities/Object";
import {InventoryFormService} from "@/common/api/api.service";
import AuthService from "../common/auth.service";
import FormSubmitButton from "../components/FormSubmitButton.vue";
import FormSubmittedMessage from "../components/FormSubmittedMessage.vue";
import NotesTextField from "../components/NotesTextField.vue";
import PageTitle from "../components/PageTitle.vue";
import PhotoManager from "@/components/Photos/PhotoManager.vue";
import PhotoThumbnail from "@/components/Photos/PhotoThumbnail.vue";
import DividingHeader from "@/components/DividingHeader.vue";
import RackAutocomplete from "@/components/V2/RackAutocomplete.vue";
import WarehouseLocationsAutocomplete from "@/components/V2/WarehouseLocationsAutocomplete.vue";
import cloneObject from "@/common/clone-object";

export default {
    components: {
        WarehouseLocationsAutocomplete,
        RackAutocomplete,
        DividingHeader,
        PhotoThumbnail,
        PhotoManager,
        FormSubmitButton,
        PageTitle,
        NotesTextField,
        FormSubmittedMessage
    },
    title: 'Inventory Form',
    data() {
        return {
            jobs: [],
            selectedJob: null,
            rackPhotos: [],
            rackPhotosUploaded: false,
            photoDialog: false,
            rackPhotoDialog: false,
            rackPhotoData: {},
            photoDialogData: {
                title: '',
                subTitle: '',
                photos: []
            },
            tabs: null,
            actionTypes: [
                "Undetermined",
                "Confirm",
                "Not Found",
                "Move"
            ],
            formData: {},
            showForm: true,
            formSubmitting: false,
            messages: [],
            serverResponseMessage: "",
            loadingImport: false,
            loading: false,
            formValid: false,
            db: {},
            inventoryItems: {},
            employeeName: "",
            presetEmployees: [],
            inventoryNotes: "",
            existingKitNames: [],
            packageTypes: ["Pallet", "Long Pallet", "Loose"],
            rackInfo: {}, // Object of all available locations (aka, location that has no products at that location)
            availableLocationsForLooseItems: {}, // Object of all available locations for loose items (all racks 1-27, 32, 33. For rack 28-31, only section + subSection that has no items on it)
            selectedLocation: false,
            racks: [],
            sections: [],
            subSections: [],
            selectedRack: "",
            selectedSection: "",
            selectedSubSection: "",
            location: null,

            availableLocations: {},
            loadingJobAndParts: false,
            actionTypes2: [
                {
                    value: 'Undetermined',
                    label: 'Undetermined',
                    icon: 'mdi-help-box',
                    activeClass: 'active btn undetermined'
                },
                {value: 'Confirm', label: 'Confirm', icon: 'mdi-check', activeClass: 'active btn confirm'},
                {value: 'Not Found', label: 'Not Found', icon: 'mdi-close', activeClass: 'active btn not-found'},
                {value: 'Move', label: 'Move', icon: 'mdi-transfer', activeClass: 'active btn move'},
            ],

            warehousePartsGroupedByPO: [], // Array of items (Object) from the server
            itemNames: [], // Array of items' names (String)
            chosenItems: [], // Name (part Number + Description) of the chosen item to be returned back to the warehouse
            chosenItems2: [], // alternate model to avoid watcher and load only inventory that is requested
            partNumbers: [], // Array of part numbers of the chosen items
            inventoryJobNumbers: [], // Array of job numbers of the chosen items
            jobNumbers: [],
            selectedJobNumber: "",
            partsGroupedByJobNumber: {}, // object of parts details by job number
            itemsOfSelectedJob: [], // array of all items of a selected job

            availableParts: [],
            selectedParts: [],
            requiredField: (v) => !!v || "This is a required field",
            partQuantityRule: [
                (v) => !!v || "This is a required field",
                (v) => (parseInt(v) >= 0 ? true : "Quantity cannot be negative"),
            ],
        };
    },
    created() {
        this.fetchData();
    },
    computed: {
        form() {
            return {
                employeeName: this.employeeName,
                inventoryNotes: this.inventoryNotes,
                parts: this.selectedParts,
            };
        },
        requiresRackPhotos() {

            if (this.rackPhotosUploaded) {
                return false;
            }

            let forceNewRackPhotos = false;

            for (let part in this.form.parts) {
                if (this.form.parts[part].newLocation) {
                    forceNewRackPhotos = true;
                    break;
                }
            }

            return forceNewRackPhotos;
        }
    },
    watch: {
        selectedJob(val) {
            this.selectedJobNumber = null;
            this.$nextTick(() => {
                this.selectedJobNumber = get(val, 'job_number');
            });
        },
        selectedRack(val) {
            if (!val) return;

            this.sections = this.rackInfo[this.selectedSection];

            // If there is selected section, reset the list of subsections
            if (this.selectedSection) {
                this.subSections = Object.keys(this.db[val][this.selectedSection]);
            }

            this.getParts();
        },
        selectedSection(val) {
            if (!val) return;

            this.subSections = Object.keys(get(this.racks, `${this.selectedRack}.${val}`, {}));
            this.getParts();
        },
        selectedSubSection(val) {
            if (!val) return;
            this.getParts();
        },
        amountAtCurrentLocation(val) {
            if (!val) return;
            this.getParts();
        },
        chosenItems(selectedItems) {
            if (!this.chosenItems) return [];

            // Reset availableParts and selectedParts
            this.availableParts = [];
            this.selectedParts = [];

            for (const item of selectedItems) {
                // const index = this.itemNames.indexOf(item);
                // Find all of the matched name (could be several)
                let indexes = [];
                for (let i = 0; i < this.itemNames.length; i++) {
                    if (this.itemNames[i].toString() === item.toString()) {
                        indexes.push(i);
                    }
                }

                // For each matched name, add the corresponding item to the availableParts
                for (const index of indexes) {

                    let partsWithThisName = Object.values(
                        this.warehousePartsGroupedByPO[index]
                    )[0];

                    for (const partInfo of partsWithThisName) {

                        let itemDetails = {
                            partPONumber: partInfo["poNo"],
                            partComboName: item,
                            partQuantity: partInfo["partQuantity"],
                            partQuantityLabel: `${partInfo['partQuantity']} in record.`,
                            manager: partInfo["manager"],
                            rack: partInfo["rack"],
                            photos: partInfo["photos"],
                            section: partInfo["section"],
                            subSection: partInfo["subSection"],
                            kitName: partInfo["kitName"],
                            inventoryStatus: "",
                            inventoryTimestamp: partInfo["inventoryTimestamp"],
                            newLocation: false,
                            warehouseInventoryItemId: partInfo['warehouseInventoryItemId'],
                            newLocationDetails: {
                                packageType: "Pallet",
                                rack: null,
                                section: null,
                                subSection: null,
                                quantity: null,
                            },
                        };

                        // Make a copy into the selectedParts array
                        const cloneItem = JSON.parse(JSON.stringify(itemDetails));
                        this.availableParts.push(cloneItem);
                        this.selectedParts.push(cloneItem);
                    }
                }
            }
        },
        async selectedJobNumber(val) {
            console.log('selectedJobNumber', val);
            // If there is no selected job number, clear the locations and parts
            if (!val) {
                this.itemsOfSelectedJob = [];
                this.availableParts = [];
                this.selectedParts = [];
                return;
            }

            const job = {
                id: get(this.selectedJob, 'id'),
                job_number: get(this.selectedJob, 'job_number'),
                company_id: get(this.selectedJob, 'company_id')
            };

            await this.fetchDatabase({jobNumbers: [val], job: job});

            this.itemsOfSelectedJob = [];

            if (this.partsGroupedByJobNumber[val]) {
                this.itemsOfSelectedJob = this.partsGroupedByJobNumber[val];
            }

            this.availableParts = [];
            this.selectedParts = [];

            for (const partInfo of this.itemsOfSelectedJob) {
                console.log(partInfo);

                let itemDetails = {
                    jobNo: partInfo["jobNo"],
                    partPONumber: partInfo["poNo"],
                    partComboName: partInfo["partComboName"],
                    partQuantity: partInfo["partQuantity"],
                    partQuantityLabel: `${partInfo['partQuantity']} in record.`,
                    manager: partInfo["manager"],
                    rack: partInfo["rack"],
                    section: partInfo["section"],
                    subSection: partInfo["subSection"],
                    kitName: partInfo["kitName"],
                    inventoryStatus: "",
                    inventoryTimestamp: partInfo["inventoryTimestamp"],
                    newLocation: false,
                    photos: partInfo["photos"],
                    warehouseInventoryItemId: partInfo['warehouseInventoryItemId'],
                    newLocationDetails: {
                        packageType: "Pallet",
                        rack: null,
                        section: null,
                        subSection: null,
                        quantity: null,
                    },
                };
                // Make a copy into the selectedParts array
                this.availableParts.push(JSON.parse(JSON.stringify(itemDetails)));
                this.selectedParts.push(JSON.parse(JSON.stringify(itemDetails)));
            }
        },
    },
    methods: {
        get,
        filterJobs(item, queryText) {
            const fields = [
                get(item, 'job_number', ''),
                get(item, 'company.name', ''),
                get(item, 'company.slug', ''),
                get(item, 'description', '')
            ].join(' ').toLowerCase();

            return fields.includes(queryText.toLowerCase());
        },
        locationSelected(location) {

            this.selectedLocation = location;

            this.availableParts = [];
            this.selectedParts = [];
            this.rackPhotos = [];
            this.selectedSubSection = "";

            if (location) {
                this.fetchDatabase({
                    warehouse_rack_id: location.rack.id,
                    warehouse_section_id: location.section.id,
                    warehouse_sub_section_id: location.subSection.id,
                }).then((data) => {
                    this.selectedRack = location.rack.name;
                    this.selectedSection = location.section.name;
                    this.selectedSubSection = location.subSection.name;
                    this.getRackPhoto();
                    this.getParts();
                });
            }
        },
        onPartNumberChange(partNumbers) {
            this.fetchDatabase({partNumbers: this.chosenItems2}).then((res) => {
                this.chosenItems = cloneObject(this.chosenItems2);
            });
        },
        async fetchData() {
            this.loadingJobAndParts = true;
            return await this.$http.get("/v3/warehouse/inventory/data")
                .then((response) => {
                    this.jobs = get(response, "data.jobs", []);
                    this.partNumbers = get(response, "data.part_numbers");
                    this.inventoryJobNumbers = get(response, "data.job_numbers");
                }).finally(() => {
                    this.loadingJobAndParts = false;
                });
        },
        getRackPhoto() {
            this.$http.get('/v2/photos/search', {
                params: {
                    rack: this.selectedRack,
                    section: this.selectedSection,
                    subSection: this.selectedSubSection
                }
            }).then((response) => {
                this.rackPhotos = get(response, 'data.photos', []);
            });
        },
        async fetchLocations() {
            return await this.$http.get('/v2/forms/inventory').then((response) => {
                //   this.rackInfo = get(response, 'data.availableLocations', {});
                //  this.racks = Object.keys(this.rackInfo);
                //  this.availableLocationsForLooseItems = get(response, 'data.availableLocationsForLooseItems', {});
            });
        },
        rackPhotoUploadFailed(error) {
            console.log('rackPhotoUploadFailed', error);
        },
        rackPhotoUploaded(photo) {
            console.log('rackPhotoUploaded', photo);
        },
        completeRackPhotoDialog() {
            let isValid = true;

            console.log('completeRackPhotoDialog', this.rackPhotoData);

            for (let item in this.rackPhotoData) {
                console.log(this.rackPhotoData[item].photos.length);
                if (this.rackPhotoData[item].photos.length === 0) {
                    isValid = false;
                }
            }

            if (isValid) {
                this.rackPhotosUploaded = true;
                this.rackPhotoDialog = false;
                this.submitForm();
            } else {
                this.$toast.error('Photos are required for racks that have changed.');
            }
        },
        async openRackPhotoDialog(data) {

            let racks = {};
            let oldRackItems = {};

            for (const part of this.form.parts) {

                if (part.newLocation) {
                    let newLocation = get(part, 'newLocationDetails');
                    let quantity = get(part, `newLocationDetails.quantity`);

                    if (quantity < 1) {
                        continue;
                    }

                    let rack = get(part, 'newLocationDetails.rack');
                    let section = get(part, `newLocationDetails.section`);
                    let subSection = get(part, `newLocationDetails.subSection`);
                    let warehouseInventoryItemId = get(part, `warehouseInventoryItemId`);
                    let newLocationKey = `${newLocation.rack}.${newLocation.section}.${newLocation.subSection}`;
                    let oldLocationKey = `${part.rack}.${part.section}.${part.subSection}`;
                    oldLocationKey = oldLocationKey.toUpperCase();

                    racks[newLocationKey] = {
                        warehouseInventoryItemId: warehouseInventoryItemId,
                        key: `${rack}.${section}.${subSection}.${warehouseInventoryItemId}`,
                        rack: rack,
                        section: section,
                        subSection: subSection,
                        quantity: quantity,
                        photos: [],
                    }

                    racks[oldLocationKey] = {
                        warehouseInventoryItemId: warehouseInventoryItemId,
                        key: `${part.rack}.${part.section}.${part.subSection}.${warehouseInventoryItemId}`,
                        rack: part.rack,
                        section: part.section,
                        subSection: part.subSection,
                        quantity: part.partQuantity,
                        photos: []
                    }

                    if (!oldRackItems[oldLocationKey]) {
                        oldRackItems[oldLocationKey] = [];
                    }

                    oldRackItems[oldLocationKey].push(part);
                }
            }

            let keys = Object.keys(oldRackItems);

            // check how many items are in the same location
            const response = await this.$http.post('/warehouse/inventory/items/counts-by-location-key', {location_keys: keys});
            const counts = get(response, 'data');

            // dont ask for new rack photos if there will be no items left in the location
            for (let oldRack in oldRackItems) {
                let itemCount = 0;
                oldRack = oldRack.toUpperCase();
                const items = oldRackItems[oldRack];

                for (const item of items) {

                    // If item qty is more than 0, its implicit that old rack still has items in it
                    if (item.partQuantity > 0) {
                        console.log('skip 0 qty');
                        continue;
                    }

                    itemCount++;
                }

                let count = 0;

                if (counts[oldRack]) {
                    count = counts[oldRack].count;
                }

                if (itemCount >= count) {
                    delete racks[oldRack];
                }
            }

            this.rackPhotoData = Object.values(racks);
            this.rackPhotoDialog = true;
        },
        async fetchDatabase(query = null) {

            if (query) {

                this.loadingImport = true;
                this.db = {};
                this.rackInfo = {};
                this.inventoryItems = {};

                const resp = await InventoryFormService
                    .getFormData(query)
                    .then(response => {

                        const json = get(response, 'data', {});
                        this.inventoryItems = get(json, 'inventoryItems', {});

                        this.presetEmployees = json['employeeNames'];
                        this.db = json["items"];
                        this.rackInfo = json["availableLocations"];

                        this.availableLocationsForLooseItems = json["availableLocationsForLooseItems"];

                        this.racks = Object.keys(this.db);

                        this.loadingImport = false;

                        this.existingKitNames = json["existingKitNames"];

                        // Set up for item lookup:
                        this.warehousePartsGroupedByPO = json["partsByPO"];
                        this.itemNames = [];
                        this.partsGroupedByJobNumber = {};

                        for (const item of this.warehousePartsGroupedByPO) {
                            this.itemNames.push(...Object.keys(item));

                            // Set up for item lookup using jobNumbers:
                            for (const [itemName, itemList] of Object.entries(item)) {
                                // `itemInfo` is an array of all items of this same name
                                for (const singleItemInfo of itemList) {

                                    // Add the item to the partsGroupedByJobNumber array
                                    if (!this.partsGroupedByJobNumber[singleItemInfo["jobNo"]]) {
                                        this.partsGroupedByJobNumber[singleItemInfo["jobNo"]] = [];
                                    }
                                    this.partsGroupedByJobNumber[singleItemInfo["jobNo"]].push({
                                        partComboName: itemName,
                                        ...singleItemInfo,
                                    });
                                }
                            }

                            this.jobNumbers = [
                                ...new Set(Object.keys(this.partsGroupedByJobNumber)),
                            ];
                        }
                    })
                    .finally(() => {
                        this.loadingImport = false;
                    });
            }
        },

        openPhotoDialog(data) {
            this.photoDialogData = data;
            this.photoDialogData.subTitle = data.partPONumber + ' | ' + data.partComboName;
            this.photoDialog = true;
        },
        submitForm() {

            if (!this.$refs.form.validate()) {
                return;
            }

            if (this.form.parts.length === 0) {
                this.$toast.error('Empty forms can not be submitted.');
                return;
            }

            if (this.requiresRackPhotos) {
                this.openRackPhotoDialog(this.form);
                return;
            }

            let user = AuthService.getUser();

            if (user) {
                this.form.employeeName = user.name;
            }

            this.loading = true;
            this.formSubmitting = true;

            InventoryFormService.submitForm(this.form)
                .then((response) => {

                    this.message = get(response, 'data.message', {
                        type: 'success',
                        text: 'Inventory Form Received.',
                        buttonText: 'New Inventory Form'
                    });

                    this.showForm = false;
                })
                .catch((err) => {
                    this.message = get(err, 'data.message', {
                        type: 'error',
                        text: 'Error submitting inventory form.',
                        buttonText: 'New Inventory Form'
                    });

                    this.showForm = true;
                    this.$toast.error(this.message.text);
                })
                .finally(() => {
                    this.formSubmitting = false;
                    this.loading = false;
                });
        },
        resetForm() {
            window.location.reload();
        },
        partQuantityRule2(part, partIndex) {
            // Sometimes, there are typo in the database and there is no quantity for a part, in that case,
            // if the status is anything by Confirm, allow empty field:

            if (part.inventoryStatus.toLowerCase() !== "confirm") return [];
            if (part.partQuantity === "") return ["This is a required field"];

            const pattern = /^\d+$/;
            return [pattern.test(part.partQuantity) || "Invalid number."];
        },
        partQuantityLabel(part) {
            return part.partQuantity + " in record";
        },
        getParts() {
            // Get all available parts if there is selected rack, section, and subsection
            if (
                !this.selectedRack ||
                !this.selectedSection ||
                !this.selectedSubSection
            )
                return;

            // Reset availableParts and selectedParts
            this.availableParts = [];
            this.selectedParts = [];

            let poNumbers = this.db[this.selectedRack][this.selectedSection][this.selectedSubSection];

            for (const [poNumber, poItems] of Object.entries(poNumbers)) {

                for (const partComboName in poItems) {

                    let itemDetails = {
                        warehouseInventoryItemId: poItems[partComboName]['warehouseInventoryItemId'],
                        partPONumber: poNumber,
                        partComboName: partComboName,
                        partQuantity: poItems[partComboName]["partQuantity"],
                        partQuantityLabel: `${poItems[partComboName]['partQuantity']} in record.`,
                        manager: poItems[partComboName]["manager"],
                        kitName: poItems[partComboName]["kitName"],
                        rack: this.selectedRack,
                        section: this.selectedSection,
                        subSection: this.selectedSubSection,
                        photos: poItems[partComboName]["photos"],
                        inventoryTimestamp: poItems[partComboName]["inventoryTimestamp"],
                        inventoryStatus: "",
                        newLocation: false,
                        newLocationDetails: {
                            packageType: "Pallet",
                            rack: null,
                            section: null,
                            subSection: null,
                            quantity: null,
                        },
                    };

                    this.availableParts.push(itemDetails);
                }
            }

            for (let ap in this.availableParts) {
                let newObj = {...this.availableParts[ap]};
                // newObj['inventoryStatus'] = 'Not yet checked'
                this.selectedParts.push(newObj);
            }
        },
        updateAmountAtCurrentLocation(part, partIndex) {
            this.selectedParts[partIndex].partQuantity = Math.max(
                this.availableParts[partIndex].partQuantity - (part.newLocationDetails.quantity || 0),
                0
            );
        },

        // Update the ability to mark an item with new location based on the 'inventoryStatus'.
        // Specifically, if 'inventoryStatus' is NOT 'confirm', then 'newLocation' is false.
        updateOnNewInventoryStatus(part, partIndex) {

            if (part.inventoryStatus.toLowerCase() === "move") {
                part.newLocation = true;
            } else {
                // Reset newLocation
                part.newLocation = false;
                // Reset newLocationDetails
                part.newLocationDetails = {
                    packageType: "Pallet",
                    rack: null,
                    section: null,
                    subSection: null,
                    quantity: null,
                };
            }

            if (part.inventoryStatus.toLowerCase() !== "confirm" && part.inventoryStatus.toLowerCase() !== "move") {
                // Reset partQuantity
                part.partQuantity = this.availableParts[partIndex].partQuantity;
            }
        },
        availableRacks(location) {

            let rackOptions = [];
            if (location.packageType.toLowerCase() === "Pallet".toLowerCase()) {
                rackOptions = Object.keys(this.rackInfo).sort((a, b) => a - b);
            } else if (
                location.packageType.toLowerCase() === "Long Pallet".toLowerCase()
            ) {
                // rackOptions = Object.keys(this.rackInfo).sort((a, b) => (a - b));
                // Only include location that has 2 consecutive available subSections 1 and 2
                for (const rack of Object.keys(this.rackInfo)) {
                    for (const section of Object.keys(this.rackInfo[rack])) {

                        if (
                            this.rackInfo[rack][section].includes(1) &&
                            this.rackInfo[rack][section].includes(2)
                        ) {
                            rackOptions.push(rack);
                            break;
                        }
                    }
                }
                console.log(
                    `Amount of rack options for Long Pallet: ${rackOptions.length}`
                );
            } else {

                rackOptions = Object.keys(this.availableLocationsForLooseItems).sort(
                    (a, b) => a - b
                );
            }

            return rackOptions;
        },
        availableSections(location) {
            // When the form is load, no rack is selected.
            if (!location.rack) return [];

            let sectionOptions = [];

            if (location.packageType.toLowerCase() == "Pallet".toLowerCase()) {
                sectionOptions = Object.keys(this.rackInfo[location.rack]).sort(
                    (a, b) => a - b
                );
            } else if (
                location.packageType.toLowerCase() == "Long Pallet".toLowerCase()
            ) {

                // rackOptions = Object.keys(this.rackInfo).sort((a, b) => (a - b));
                // Only include location that has 2 consecutive available subSections 1 and 2
                for (const section of Object.keys(this.rackInfo[location.rack])) {
                    if (
                        this.rackInfo[location.rack][section].includes(1) &&
                        this.rackInfo[location.rack][section].includes(2)
                    ) {
                        sectionOptions.push(section);
                    }
                }
            } else {
                sectionOptions = Object.keys(
                    this.availableLocationsForLooseItems[location.rack]
                ).sort((a, b) => a - b);
            }
            return sectionOptions;
        },
        availableSubSections(location) {

            if (!location.rack || !location.section) return [];

            let subSectionOptions = [];

            if (location.packageType.toLowerCase() !== "Loose".toLowerCase()) {
                subSectionOptions = [...this.rackInfo[location.rack][location.section]];
            } else {
                subSectionOptions = [
                    ...this.availableLocationsForLooseItems[location.rack][
                        location.section
                        ],
                ];
            }

            // if the package type is 'Long Pallet', then the subSection is "1,2"
            if (location.packageType.toLowerCase() === "Long Pallet".toLowerCase()) {
                subSectionOptions = ["1,2"];
                // this.locations[locationIndex]["subSection"] = "1,2";
                this.rackInfo[location.rack][location.section]["subSection"] = "1,2";
            }
            return subSectionOptions;
        },
    },
};
</script>
<style>
.inventory-table tr td {
    padding: 10px 10px !important;
}

.inventory-table tr:nth-child(even) td {
    background-color: #353535;
}
</style>
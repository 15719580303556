<script>
import PageTitle from "@/components/PageTitle.vue";
import {get} from "@/client/utilities/Object";
import PhotoThumbnail from "@/components/Photos/PhotoThumbnail.vue";
import RackAutocomplete from "@/components/V2/RackAutocomplete.vue";
import SectionAutocomplete from "@/components/V2/SectionAutocomplete.vue";
import SubSectionAutocomplete from "@/components/V2/SubSectionAutocomplete.vue";
import PartName from "@/components/V2/PartName.vue";
import PackageTypeAutocomplete from "@/components/V2/PackageTypeAutocomplete.vue";
import PhotoManager from "@/components/Photos/PhotoManager.vue";
import FormSubmittedMessage from "@/components/FormSubmittedMessage.vue";
import TextInput from "@/components/TextInput.vue";
import DatePicker from "@/components/V2/DatePicker.vue";
import DeliveryWindowSelect from "@/components/V2/DeliveryWindowSelect.vue";
import NotesTextFieldV2 from "@/components/V2/NotesTextField-V2.vue";
import cloneObject from "@/common/clone-object";
import {WarehouseInventoryService} from "@/common/api/api.service";
import {LOG_STYLES} from "@/common/log";
import printJS from "print-js";
import {API_ROOT} from "@/common/environment";
import NotesTextField from "@/components/NotesTextField.vue";
import {each} from "lodash";

export default {
    title: 'Receiving Request Approval',
    logContext: 'ApproveReceivingRequest',
    logStyle: LOG_STYLES.TEAL,
    components: {
        NotesTextField,
        NotesTextFieldV2,
        DeliveryWindowSelect,
        DatePicker, TextInput,
        FormSubmittedMessage,
        PhotoManager,
        PackageTypeAutocomplete,
        PartName, SubSectionAutocomplete, SectionAutocomplete, RackAutocomplete, PhotoThumbnail, PageTitle
    },
    data() {
        return {
            locationFormValid: true,
            jobInventoryItems: [],
            panel: false,
            rules: {
                required: v => !!v || "This is a required field",
            },
            delivery: {
                site_contact_name: '',
                delivery_window: '',
                delivery_date: '',
                delivery_notes: '',
            },
            formSubmitted: false,
            formSubmitting: false,
            currentLocation: 0,
            availableParts: [],
            locations: [],
            locationSkeleton: {
                parts: [],
                photos: [],
                package_type: null,
                rack: null,
                section: null,
                sub_section: null,
                line_items: [
                    {
                        name: "",
                        received_quantity: 0,
                        selected: {}
                    }
                ]
            },
            package_types: [],
            request: {
                data: null
            },
            purchase_order: null,
            job: null,
            step: 1,
            method: null,
            multipleLocations: false,
            jobLookupLoading: false,
            methodDescription: {
                warehouse: 'Receive items in Warehouse',
                delivery: 'Send items to Delivery'
            },
            dialogLabelPreview: false, // Dialog for label preview
            labelUrl: null,
            steps: {
                1: {
                    name: 'Select Method',
                    isValid: () => {
                        return this.method !== null;
                    }
                },
                2: {
                    name: 'Approve Items',
                    isValid: () => {
                        let valid = true;

                        if (this.method === null) {
                            valid = false
                        }

                        if (this.method === 'warehouse') {

                            if (this.$refs.locationForm) {
                                this.$refs.locationForm.validate();
                            }

                            /*
                            each(this.locations, (item) => {
                                if (item.package_type === null) {
                                    valid = false
                                }

                                if (item.rack === null) {
                                    valid = false
                                }

                                if (item.section === null) {
                                    valid = false
                                }

                                if (item.sub_section === null) {
                                    valid = false
                                }
                            });
                             */

                            valid = this.locationFormValid;
                        }

                        if (this.method === 'delivery') {

                            if (this.delivery.site_contact_name.length === 0) {
                                valid = false
                            }

                            if (this.delivery.delivery_window.length === 0) {
                                valid = false
                            }

                            if (this.delivery.delivery_date.length === 0) {
                                valid = false
                            }
                        }

                        return valid;
                    }
                },
                3: {
                    name: 'Print Labels',
                    isValid: () => {
                        let valid = true;

                        for (const location of this.locations) {
                            for (const line of location.line_items) {
                                if (get(line, 'label_printed', false) === false) {
                                    valid = false
                                }
                            }
                        }

                        return this.method !== null && valid;
                    }
                },
                4: {
                    name: 'Upload Location Photos',
                    isValid: () => {
                        let valid = true;

                        if (this.method === null) {
                            valid = false
                        }

                        if (this.method === 'delivery') {
                            return true;
                        }

                        for (const location of this.locations) {
                            if (!location.photos || location.photos.length === 0) {
                                valid = false
                            }
                        }

                        return valid;
                    }
                },
                5: {
                    name: 'Upload Delivery Photos',
                    isValid: () => {
                        return this.method !== null;
                    }
                }
            }
        }
    },
    computed: {
        foremanName() {
            return get(this.job, 'foreman.name', '');
        },
        jobName() {
            return get(this.form, 'purchase_order.purchase_order_job.description');
        },
        poNumber() {
            return get(this.form, 'purchase_order.po_number');
        },
        purchaseOrderId() {
            return get(this.form, 'purchase_order.id');
        },
        packingSlipThumbnailUrl() {
            let photo = this.form.packing_slip_photo.photo;
            return get(photo, 'thumbnails.0.url');
        },
        currentUrl() {
            return window.location.href;
        }
    },
    methods: {
        get,
        async getJobItemsInWarehouse() {
            this.jobLookupLoading = true;
            // Get the jobNumber by the first 7 characters of the poNumber
            let jobNumber = get(this.request, 'purchase_order.job_number');

            const response = await WarehouseInventoryService.byJobNumber(jobNumber);
            const results = await response.data;
            this.jobLookupLoading = false;
            try {
                console.log("Job's Items lookup successful!");
                // console.log(results);
                if (results.status !== "success") {

                } else {

                    // Reset

                    this.jobInventoryItems = results["itemsByJob"];
                    this.panel = 0;

                }
            } catch (err) {
                console.log(err);
            }
        },
        async fetchFormRequest() {
            let uuid = get(this.$route, 'params.uuid');
            if (uuid) {
                return this.$http.get('/v2/forms/requests/receiving/' + uuid).then(response => {
                    this.request = response.data;
                });
            } else {
                this.$toast.error('No Form Request Found');
            }
        },
        changeStep(step) {

            if (this.stepValid(step - 1)) {
                this.step = step;
                this.updateFormRequest();
            }
        },
        validate(step) {
            if (this.stepValid(step)) {
                this.step = step + 1;
            }
        },
        stepValid(stepNumber) {

            let step = get(this.steps, stepNumber);

            if (step) {
                return step.isValid();
            }
        },
        stepEditable(stepNumber) {
            let valid = true;

            for (const idx in this.steps) {

                const step = this.steps[idx];

                if (idx < stepNumber && !step.isValid()) {
                    valid = false;
                }
            }

            return valid;
        },
        async fetchPackageTypes() {
            return this.$http.get('/v2/forms/receive/locations').then(response => {
                this.package_types = response.data.package_types;
            });
        },
        getAvailableParts() {
            let parts = [];

            for (const lineItem of this.request.data.line_items) {
                let thumbnail = get(lineItem, 'photos.0.thumbnails.0.url');

                let part = {
                    id: lineItem.id,
                    part_number: lineItem.part_number,
                    part_description: lineItem.part_description,
                    avatar: get(lineItem, 'photos.0.thumbnails.75.url', thumbnail),
                };
                parts.push(part);
            }

            /*
            each(this.request.data.line_items, (lineItem, idx) => {

                let thumbnail = get(lineItem, 'photos.0.thumbnails.0.url');

                let part = {
                    id: lineItem.id,
                    part_number: lineItem.part_number,
                    part_description: lineItem.part_description,
                    avatar: get(lineItem, 'photos.0.thumbnails.75.url', thumbnail),
                };
                parts.push(part);
            }) */

            return parts;
        },
        addLocation(location) {

            const skeleton = JSON.parse(JSON.stringify(this.locationSkeleton));
            skeleton.parts = JSON.parse(JSON.stringify(this.getAvailableParts()));

            this.locations.push(skeleton);
            this.currentLocation++;
        },
        removeLocation(idx) {
            const _locations = this.locations;
            let locations = [];

            if (_locations.length <= 1) {
                this.$toast.warning('Cannot remove last location, there must be at least 1 location to receive items.');
                return false;
            }

            for (const idx2 in _locations) {
                if (idx2 !== idx) {
                    locations.push(_locations[idx2]);
                }
            }

            this.locations = locations;
        },
        addItem(locationIndex) {
            this.locations[locationIndex].line_items.push({
                name: "",
                label_printed: false,
                received_quantity: 0,
                selected: {}
            });
        },
        removeItem(item, location) {
            const idx = location.line_items.indexOf(item);
            location.line_items.splice(idx, 1);
        },
        resetForm() {
            this.$router.push({path: '/approval/requests'});
        },
        async updateFormRequest(status = null) {
            let uuid = this.$route.params.uuid;

            let data = this.request.data;

            let url = `/v2/forms/requests/receiving/${uuid}`;

            if (status) {
                url += `/${status}`;
            }

            data.locations = cloneObject(this.locations);
            data.method = this.method;
            data.delivery = this.delivery;

            return this.$http.post(url, data)
                .then(response => {
                    return response.data;
                })
        },
        submitForm() {
            console.log('Submitting form...');
            this.formSubmitting = true;

            let data = cloneObject(this.request);
            data.locations = cloneObject(this.locations);

            this.updateFormRequest('approved')
                .then(response => {
                    this.formSubmitted = true;
                })
                .catch(response => {
                    this.$toast.error('Error approving receiving request.');
                    this.formSubmitting = false;
                });
        },
        printAllLabels(locations) {
            let ids = [];
            for (const idx in locations) {
                const location = locations[idx];
                for (const idx2 in location.line_items) {
                    ids.push(location.line_items[idx2].warehouse_inventory_item_id);
                    locations[idx].line_items[idx2].label_printed = true;
                }
            }

            const id_string = ids.join(',');
            window.open(`/api/labels/${id_string}?format=html`);
        },
        doPrintLabel(url) {
            let _url = `${API_ROOT}/labels/${url}?format=pdf`;
            printJS({printable: _url, type: 'pdf'});
        },
        printLabel(item, locationIndex, itemIndex) {

            this.doPrintLabel(item.warehouse_inventory_item_id);

            setTimeout(() => {
                item.label_printed = true;
                item.printed_by.date = new Date();
            }, 1000);

            this.updateFormRequest();
        },
        formValid() {
            let valid = true;

            each(this.steps, (step, idx) => {
                if (!step.isValid()) {
                    valid = false;
                }
            });

            return valid && !this.form.submitting;
        },
    },
    async mounted() {

        await this.fetchPackageTypes();
        await this.fetchFormRequest();
        this.availableParts = this.getAvailableParts();

        let existing_locations = cloneObject(get(this.request, 'data.locations', []));

        let method = get(this.request, 'data.method');

        if (method === 'warehouse' || method === 'delivery') {
            this.method = method;
            this.changeStep(2);
        }

        let po_number = get(this.request, 'data.purchase_order.po_number');
        const po = await this.$http.get('/v2/purchase-order/' + po_number);

        this.purchase_order = get(po, 'data');
        this.job = get(this.purchase_order, 'job');

        this.delivery.site_contact_name = this.foremanName;

        try {

            if (existing_locations.length === 0) {
                this.addLocation();
                let line_items = cloneObject(this.request.data.line_items);

                this.locations[0].line_items = line_items.filter(item => {
                    return item.deleted !== true && item.received_quantity > 0;
                });

                this.locations[0].line_items.map(item => {
                    item.selected = JSON.parse(JSON.stringify(item));
                    item.selected.photos = undefined;
                    item.label_printed = false;
                    item.printed_by = {
                        user_id: get(this.user, 'id'),
                        date: null
                    };
                    return item;
                });
            } else {
                existing_locations.map((location, index) => {
                    location.package_types = cloneObject(this.package_types);
                    return location;
                });

                this.locations = existing_locations;
            }

            //this.locations = JSON.parse(JSON.stringify(this.locations));
            this.locations = cloneObject(this.locations);

        } catch (e) {
            console.error(e);
        }
    }
}
</script>

<template>
    <VContainer>
        <PageTitle>
            Approve Receiving Request
        </PageTitle>

        <FormSubmittedMessage
            v-show="formSubmitted"
            :on-click="resetForm"
            button-text="View Approval Requests"
            title="Form Request Approved"
        >
            Request Approved.
        </FormSubmittedMessage>

        <div v-show="!formSubmitted">
            <VRow class="my-2 mx-0 py-1 px-2 grey darken-4">
                <VCol class="px-2 py-2">
                    <i class="grey--text">PO #</i> <br>
                    {{ get(request, 'data.purchase_order.po_number') }}
                </VCol>
                <VCol class="px-2 py-2">
                    <i class="grey--text">Company</i> <br>
                    {{ get(request, 'data.purchase_order.company.name') }}
                </VCol>
                <VCol class="px-2 py-2">
                    <i class="grey--text">Job</i> <br>
                    {{ get(request, 'data.purchase_order.purchase_order_job.description') }}
                </VCol>
                <VCol class="px-2 py-2">
                    <i class="grey--text">Expected Delivery Date</i> <br>
                    {{ get(request, 'data.purchase_order.expiration_date') }}
                </VCol>
                <VCol v-if="request.data && request.data.packing_slip_photos" class="px-2 py-2">
                    <i class="grey--text">Packing Slips</i> <br>
                    <PhotoThumbnail
                        v-for="photo in request.data.packing_slip_photos"
                        :key="`approve-receive-req-packing-slip-photo-${photo.id}`"
                        :photo="photo"
                        width="40"
                    />
                </VCol>
            </VRow>

            <VStepper v-model="step"
                      non-linear
                      vertical
            >
                <!-- step 1 -->
                <VStepperStep
                    :complete="step > 1"
                    :rules="[v => stepValid(1)]"
                    complete-icon="mdi-check"
                    edit-icon="mdi-check"
                    editable
                    error-icon="mdi-alert-octagon"
                    step="1"
                >
                    {{ steps[1].name }}
                    <small v-if="!stepValid(1)" class=pt-1>
                        Please select a method
                    </small>
                </VStepperStep>
                <VStepperContent step="1">
                    <VCard class="grey darken-3">
                        <VCardTitle>
                            How would you like to receive these items?
                        </VCardTitle>
                        <VCardText>

                            <VBtn
                                class="mr-4 accent"
                                x-large
                                @click="method = 'warehouse'; changeStep(2)"
                            >
                                Warehouse
                            </VBtn>

                            <VBtn class="mr-4" disabled text>
                                Or
                            </VBtn>

                            <VBtn
                                :disabled="true"
                                class="yellow darken-3"
                                x-large
                                @click="method = 'delivery'; changeStep(2)"
                            >
                                Delivery
                            </VBtn>
                        </VCardText>
                    </VCard>
                </VStepperContent>
                <!-- end step 1 -->

                <!-- step 2 -->
                <VStepperStep
                    :complete="step > 2"
                    :rules="[v => stepValid(2)]"
                    complete-icon="mdi-check"
                    edit-icon="mdi-check"
                    editable
                    error-icon="mdi-alert-octagon"
                    step="2"
                >
                    {{ method ? methodDescription[method] : 'Select a method' }}
                </VStepperStep>

                <VStepperContent step="2">
                    <VRow>
                        <VCol>
                            <VBtn color="accent" @click="getJobItemsInWarehouse">View Job Inventory</VBtn>
                            <v-expansion-panels
                                v-show="jobInventoryItems.length > 0"
                                v-model="panel"
                                class="mt-4"
                            >
                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        View Job Inventory
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-simple-table
                                            fixed-header
                                            height="400"
                                        >
                                            <template v-slot:default>
                                                <thead>
                                                <tr>
                                                    <th>PO #</th>
                                                    <th>Part Number</th>
                                                    <th>Part Description</th>
                                                    <th>Quantity</th>
                                                    <th>Location</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr
                                                    v-for="(item, partIndex) in jobInventoryItems"
                                                    :key="partIndex"
                                                >
                                                    <td>
                                                        {{ item.poNo }}
                                                    </td>
                                                    <td>
                                                        {{ item.partNumber }}
                                                    </td>
                                                    <td>
                                                        {{ item.partDescription }}
                                                    </td>
                                                    <td>
                                                        {{ item.partQuantity }}
                                                    </td>
                                                    <td>
                                                        {{ item.rack }}.{{ item.section }}.{{ item.subSection }}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </template>

                                        </v-simple-table>

                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </VCol>
                    </VRow>
                    <div v-if="method === 'warehouse'">
                        <VRow
                            class="my-6 d-flex justify-space-around"
                        >
                            <VCol class="d-flex justify-space-around">
                                <VBtn
                                    color="addLocation"
                                    @click="addLocation"
                                >
                                    <v-icon left>mdi-map-marker-plus</v-icon>
                                    Add Location
                                </VBtn>
                            </VCol>
                            <VCol class="d-flex justify-space-around">
                                <VBtn
                                    :disabled="locations.length <= 1"
                                    color="removeLocation"
                                    @click="removeLocation(locations.length-1)"
                                >
                                    <VIcon left>mdi-map-marker-minus</VIcon>
                                    Remove Location
                                </VBtn>
                            </VCol>
                        </VRow>
                        <VForm
                            ref="locationForm"
                            v-model="locationFormValid"
                            lazy-validation
                        >
                            <VCard
                                v-for="(location, locationIndex) in locations"
                                :key="locationIndex"
                            >
                                <VCardTitle>
                                    <VRow>
                                        <VCol>
                                            Location {{ locationIndex + 1 }}
                                        </VCol>
                                        <VCol class="text-right">
                                            <VBtn :disabled="locations.length <= 1" class="removeLocation" right
                                                  small
                                                  @click="removeLocation(locationIndex)"
                                            >
                                                <VIcon left>mdi-close</VIcon>
                                                Remove Location
                                            </VBtn>
                                        </VCol>
                                    </VRow>
                                </VCardTitle>
                                <VCardText>
                                    <VRow>
                                        <VCol>
                                            <PackageTypeAutocomplete
                                                v-model="location.package_type"
                                                :items="package_types"
                                                :rules="[rules.required]"
                                            ></PackageTypeAutocomplete>
                                        </VCol>
                                        <VCol>
                                            <RackAutocomplete
                                                v-model="location.rack"
                                                :disabled="!location.package_type"
                                                :package-type="location.package_type"
                                                :rules="[rules.required]"
                                                @change="location.section = null;"
                                            />
                                        </VCol>
                                        <VCol>
                                            <SectionAutocomplete
                                                v-model="location.section"
                                                :disabled="!location.rack"
                                                :rack="location.rack"
                                                :rules="[rules.required]"
                                                @change="location.sub_section = null"
                                            />
                                        </VCol>
                                        <VCol>
                                            <SubSectionAutocomplete
                                                v-model="location.sub_section"
                                                :disabled="!location.section"
                                                :rules="[rules.required]"
                                                :section="location.section"
                                            />
                                        </VCol>
                                    </VRow>
                                </VCardText>
                                <VCardText
                                    v-for="(item, idx) in location.line_items"
                                    :key="`locationIndex-${idx}`"
                                    class="py-0 my-0"
                                >
                                    <VRow class="align-center">
                                        <VCol cols="1">
                                            {{ idx + 1 }}
                                        </VCol>
                                        <VCol cols="6">
                                            <VAutocomplete
                                                v-model="item.selected"
                                                :items="location.parts"
                                                :rules="[rules.required]"
                                                hide-details
                                                item-text="name"
                                                item-value="id"
                                                label="Part"
                                                outlined
                                                required
                                                return-object
                                            >
                                                <template v-slot:selection="{item}">
                                                    <VChip>
                                                        <VAvatar
                                                            left
                                                            width="20"
                                                        >
                                                            <VImg :src="item.avatar"></VImg>
                                                        </VAvatar>
                                                        {{ item.part_number }} {{ item.part_description }}
                                                    </VChip>
                                                </template>
                                                <template v-slot:item="{item}">
                                                    <v-list-item-avatar
                                                        tile
                                                        width="20"
                                                    >

                                                        <img
                                                            :src="get(item, 'photos.0.thumbnails.0.url', item.avatar)"/>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title
                                                            v-html="item.part_number"></v-list-item-title>
                                                        <v-list-item-subtitle
                                                            v-html="item.part_description"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                            </VAutocomplete>
                                        </VCol>
                                        <VCol>
                                            <VTextField
                                                v-model="item.received_quantity"
                                                :disabled="item.deleted"
                                                :label="`${item.quantity} available`"
                                                :rules="[v => (v <= item.quantity && v >= 0) || `Maximum quantity is  ${item.received_quantity}`]"
                                                hide-details
                                                outlined
                                                type="number"
                                            >

                                            </VTextField>
                                        </VCol>
                                        <VCol>
                                            <VBtn
                                                class="mb-7"
                                                color="removeLocation"
                                                outlined
                                                @click="location.line_items.splice(idx, 1)"
                                            >
                                                <VIcon left>mdi-close</VIcon>
                                                Remove
                                            </VBtn>
                                        </VCol>
                                    </VRow>
                                    <VDivider class="my-3"></VDivider>
                                </VCardText>
                            </VCard>
                        </VForm>

                        <VRow class="mt-2">
                            <VCol>
                                <VBtn
                                    color="primary"
                                    @click="changeStep(3)"
                                >
                                    Next
                                </VBtn>
                            </VCol>
                        </VRow>
                    </div>
                    <div v-if="method === 'delivery'">
                        <VCard>
                            <VCardTitle>Delivery Details</VCardTitle>
                            <VCardText>
                                <VRow>
                                    <VCol cols="4">
                                        <TextInput
                                            v-model="delivery.site_contact_name"
                                            :rules="[rules.required]"
                                            label="Site Contact Name"
                                        />
                                    </VCol>
                                    <VCol cols="4">
                                        <DatePicker
                                            v-model="delivery.delivery_date"
                                            :rules="[rules.required]"
                                            hint="YYYY-MM-DD Format"
                                            label="Delivery Date"
                                        />
                                    </VCol>
                                    <VCol cols="4">
                                        <DeliveryWindowSelect
                                            v-model="delivery.delivery_window"
                                            :rules="[rules.required]"
                                            label="Delivery Window"
                                        />
                                    </VCol>
                                </VRow>
                                <VRow>
                                    <VCol>
                                        <NotesTextField v-model="delivery.delivery_notes"
                                                        label="Delivery Notes"
                                        />
                                    </VCol>
                                </VRow>
                            </VCardText>
                        </VCard>
                        <VCard
                            v-for="(location, locationIndex) in locations"
                            :key="locationIndex"
                            class="pb-4"
                        >
                            <VCardSubtitle>
                                Items
                            </VCardSubtitle>
                            <VCardText
                                v-for="(item, idx) in location.line_items"
                                :key="`line-item-index-${idx}`"
                                class="py-0 my-0 mb-3"
                            >
                                <VRow class="align-center">
                                    <VCol cols="1">
                                        {{ idx + 1 }}
                                    </VCol>
                                    <VCol cols="6">
                                        <PartName
                                            :part-description="item.part_description"
                                            :part-number="item.part_number"
                                        />
                                    </VCol>
                                    <VCol>
                                        <VTextField
                                            v-model="item.received_quantity"
                                            :disabled="item.deleted"
                                            :label="`${item.quantity} available`"
                                            :rules="[v => (v <= item.quantity && v >= 0) || `Maximum quantity is  ${item.received_quantity}`]"
                                            hide-details
                                            outlined
                                            type="number"
                                        >
                                        </VTextField>
                                    </VCol>
                                </VRow>
                                <VDivider class="my-3"></VDivider>
                            </VCardText>
                        </VCard>

                        <VRow class="mt-4">
                            <VCol>
                                <VBtn
                                    color="primary"
                                    @click="changeStep(5)"
                                    @disable="!stepValid(2)"
                                >
                                    Next
                                </VBtn>
                            </VCol>
                        </VRow>
                    </div>
                </VStepperContent>
                <!-- end step 2 -->

                <!-- step 3 -->
                <VStepperStep
                    v-if="method === 'warehouse'"
                    :complete="step > 3"
                    :rules="[v => stepValid(3)]"
                    complete-icon="mdi-check"
                    edit-icon="mdi-check"
                    editable
                    error-icon="mdi-alert-octagon"
                    step="3"
                >
                    Print Labels
                    <small v-if="!stepValid(3)" class=pt-1>
                        Please print labels
                    </small>
                </VStepperStep>
                <VStepperContent step="3">
                    <VRow>
                        <VCol>
                            <VBtn
                                class="mb-4"
                                color="accent"
                                @click="printAllLabels(locations)"
                            >
                                <VIcon left>mdi-printer</VIcon>
                                Print All Labels
                            </VBtn>
                        </VCol>
                    </VRow>
                    <VCard
                        v-for="(location, locationIndex) in locations"
                        :key="`approve-recv-req-location-print-labels-${locationIndex}`"
                    >
                        <VCardTitle>
                            Print Labels for
                            {{ get(location, 'rack.rack') }}.{{
                                get(location, 'section.section')
                            }}.{{ get(location, 'sub_section.sub_section') }}
                        </VCardTitle>
                        <VCardText>
                            <VRow
                                v-for="(item, itemIndex) in location.line_items"
                                :key="`labels-${locationIndex}-${itemIndex}`"
                            >
                                <VCol cols="2">
                                    <PhotoThumbnail :photo="get(item, 'photos.0', {})"></PhotoThumbnail>
                                </VCol>
                                <VCol cols="7">
                                    <PartName
                                        :part-description="item.part_description"
                                        :part-number="item.part_number"
                                    ></PartName>
                                </VCol>
                                <VCol cols="3">
                                    <VRow
                                        v-show="item.label_printed === false"
                                        no-gutters
                                    >
                                        <VCol>
                                            <VBtn
                                                class="mb-7"
                                                color="primary"
                                                small
                                                @click="printLabel(item, locationIndex, itemIndex)"
                                            >
                                                <VIcon left>mdi-printer</VIcon>
                                                Label
                                            </VBtn>
                                        </VCol>
                                        <VCol>
                                            <VIcon class="ml-2" color="error" small>mdi-alert-octagon-outline</VIcon>
                                        </VCol>
                                    </VRow>

                                    <VRow v-show="item.label_printed === true" no-gutters>
                                        <VCol>
                                            <VBtn
                                                class="mb-7"
                                                color="accent"
                                                small
                                                @click="printLabel(item, locationIndex, itemIndex)"
                                            >
                                                <VIcon left>mdi-printer</VIcon>
                                                Re-Print
                                            </VBtn>
                                        </VCol>
                                    </VRow>
                                </VCol>
                            </VRow>
                        </VCardText>
                    </VCard>

                    <VRow class="mt-6">
                        <VCol>
                            <VBtn
                                :disabled="!stepValid(3)"
                                color="primary"
                                x-large
                                @click="changeStep(4)"
                            >
                                Next
                            </VBtn>
                        </VCol>
                    </VRow>
                </VStepperContent>

                <!-- step 4 -->
                <VStepperStep
                    v-if="method === 'warehouse'"
                    :complete="step > 4"
                    :rules="[v => stepValid(4)]"
                    complete-icon="mdi-check"
                    edit-icon="mdi-check"
                    editable
                    error-icon="mdi-alert-octagon"
                    step="4"
                >
                    {{ steps[4].name }}
                    <small v-if="!stepValid(4)" class=pt-1>
                        Please upload location photos
                    </small>
                </VStepperStep>
                <VStepperContent step="4">
                    <VCard
                        v-for="(location, locationIndex) in locations"
                        :key="`lc-${locationIndex}`"
                    >
                        <VCardTitle>
                            Photos for
                            {{ get(location, 'rack.rack') }}.{{
                                get(location, 'section.section')
                            }}.{{ get(location, 'sub_section.sub_section') }}
                        </VCardTitle>
                        <VCardText>
                            <PhotoManager
                                :app-notification-content="`Upload photo for ${locationIndex}`"
                                :app-notification-url="currentUrl + '?location_index=' + locationIndex"
                                :photos="location.photos"
                                :purchase-order-id="purchaseOrderId"
                                :rack="get(location, 'rack.rack')"
                                :section="get(location,'section.section')"
                                :sub-section="get(location,'sub_section.sub_section')"
                                app-notification-title="Upload Item Photo"
                                category="receiving"
                            />
                        </VCardText>
                    </VCard>

                    <VRow class="mt-6">
                        <VCol>
                            <VBtn
                                :disabled="!stepValid(4) || formSubmitting"
                                :loading="formSubmitting"
                                color="primary"
                                x-large
                                @click="submitForm"
                            >
                                Submit
                            </VBtn>
                        </VCol>
                    </VRow>
                </VStepperContent>

                <!-- step 5 -->
                <VStepperStep
                    v-if="method === 'delivery'"
                    :complete="step > 5"
                    :rules="[v => stepValid(5)]"
                    complete-icon="mdi-check"
                    edit-icon="mdi-check"
                    editable
                    error-icon="mdi-alert-octagon"
                    step="5"
                >
                    {{ steps[5].name }}
                    <small v-if="!stepValid(5)" class=pt-1>
                        Please upload delivery photos
                    </small>
                </VStepperStep>
                <VStepperContent step="5">
                    <VCard
                        v-for="(location, locationIndex) in locations"
                        :key="`ap-rec-req-locations-delivery-photos-${locationIndex}`"
                    >
                        <VCardTitle>
                            Delivery Photos
                        </VCardTitle>
                        <VCardText>
                            <PhotoManager
                                :app-notification-content="`Upload photo for ${locationIndex}`"
                                :app-notification-url="currentUrl + '?location_index=' + locationIndex"
                                :photos="location.photos"
                                :purchase-order-id="purchaseOrderId"
                                app-notification-title="Upload Item Photo"
                                category="receiving"
                                @uploaded="updateFormRequest()"
                            />
                        </VCardText>
                    </VCard>

                    <VRow class="mt-6">
                        <VCol>
                            <VBtn
                                :disabled="formSubmitting"
                                :loading="formSubmitting"
                                color="primary"
                                x-large
                                @click="submitForm"
                            >
                                Submit
                            </VBtn>
                        </VCol>
                    </VRow>
                </VStepperContent>
            </VStepper>
        </div>

        <v-dialog v-model="dialogLabelPreview" max-width="500px">
            <v-card>
                <v-card-title class="text-h5">Label</v-card-title>
                <v-card-text>
                    <iframe :src="labelUrl" frameborder="0" height="100%" width="100%"></iframe>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" text @click="dialogLabelPreview = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <div id="label-preview"></div>
    </VContainer>
</template>

<style scoped>

</style>